import React, { useState } from 'react';

import { Text } from '@goodfynd/react-web.typography.text';
import { useTheme } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';

import { Image, Loading, Modal } from '../../components';
import { Icon } from '../../components/Icon';
import { creditCards } from '../../config/images';
import strings from '../../config/strings';
import { useOrderActions } from '../../hooks/useOrderActions';
import { CreditCard } from '../../stores/models/customer/credit-card';
import {
  StyledCreditAvatar,
  StyledPaymentButtonTextContainer,
  StyledPaymentMethodButton,
  StyledPaymentMethodsContent,
  StyledPaymentMethodsContentBottom,
  StyledPaymentMethodsContentTop,
} from './styles';

import type { PaymentMethodsProps } from './types';
export default function PaymentMethods({
  cards = [],
  setPaymentMethod,
  showCreditForm,
  ...props
}: PaymentMethodsProps) {
  const { palette } = useTheme();
  const [busy, setBusy] = useState('');
  const { removeCreditCard } = useOrderActions();

  const cardImages: any = creditCards[palette.mode];

  const handleRemoveCard =
    (card: CreditCard) => async (e: ClickButtonEvent) => {
      e.stopPropagation();

      const confirmed = confirm(
        `Are you sure you want to permanently remove this card: ${card.label}`
      );

      if (confirmed) {
        setBusy(card.token);
        try {
          await removeCreditCard(card);
        } finally {
          setBusy('');
        }
      }
    };

  return (
    <Modal
      containerCss={{
        maxWidth: 425,
        minWidth: 425,
      }}
      footer={
        <StyledPaymentMethodsContentBottom>
          <StyledPaymentMethodButton fullWidth onClick={showCreditForm}>
            <StyledPaymentButtonTextContainer>
              <Icon name="card" />
              <Text marginLeft={16} type="subh2">
                {strings.labels.addCreditCard}
              </Text>
            </StyledPaymentButtonTextContainer>

            <Icon name="chevron_right" />
          </StyledPaymentMethodButton>
        </StyledPaymentMethodsContentBottom>
      }
      gtmType="payment_methoods"
      header={
        <Text as="h5" type="h5">
          {strings.titles.choosePaymentMethod}
        </Text>
      }
      {...props}
    >
      <div>
        <StyledPaymentMethodsContent>
          <StyledPaymentMethodsContentTop>
            <Text type="subh3">{strings.titles.savedCards}</Text>

            <List>
              {cards.map((card) => {
                const image =
                  cardImages.hasOwnProperty(card.cardType) &&
                  cardImages[card.cardType];
                return (
                  <ListItem
                    key={card.id}
                    onClick={() => {
                      setPaymentMethod(card);
                      props.close();
                    }}
                    secondaryAction={
                      <IconButton
                        aria-label="Delete Card"
                        disabled={!!busy}
                        edge="end"
                        onClick={handleRemoveCard(card)}
                      >
                        {busy === card.id ? (
                          <Loading type="circle" />
                        ) : (
                          <Icon name="delete" />
                        )}
                      </IconButton>
                    }
                    sx={{
                      cursor: 'pointer',
                      paddingLeft: 0,
                    }}
                  >
                    <ListItemAvatar>
                      <StyledCreditAvatar>
                        {!!image ? (
                          <Image height={28} width={45} src={image} />
                        ) : (
                          <Icon name="card" />
                        )}
                      </StyledCreditAvatar>
                    </ListItemAvatar>
                    <ListItemText
                      primary={
                        <Text type="subh2">{`${card.cardType.toUpperCase()}...${
                          card.lastFour
                        }`}</Text>
                      }
                      secondary={`Exp. ${card.expirationMonth.padStart(
                        2,
                        '0'
                      )}/${card.expirationYear.slice(2, 4)}`}
                    />
                  </ListItem>
                );
              })}
            </List>
          </StyledPaymentMethodsContentTop>
        </StyledPaymentMethodsContent>
      </div>
    </Modal>
  );
}
